<template>
  <section class="uiv">
    <slot name="modal-trigger" :open-modal="openModal">
      <a href="" class="normal-weight size-text-13px" @click.prevent="openModal">{{ prompt }}</a>
    </slot>

    <modal v-model="modalOpen" :size="modalSize" :header="false" :footer="false" :backdrop="backdrop" :keyboard="false" @hide="cancel" append-to-body>
      <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          {{ title }}
        </div>

        <div class="notice-regular-text">
          <validation-observer v-slot="{ handleSubmit, failed }" ref="validationObserver">
            <form role="form" name="form" class="a-form" novalidate @submit.prevent>
              <div class="bottom-30">
                <div v-if="copyBuilt">
                  <slot name="default"></slot>
                </div>
              </div>

              <div class="row tight-columns">
                <div class="col-sm-4">
                  <loading-button name="editModalSubmit" :lb-class="[saveButtonClass, 'a-button-size']" @lb-click="handleSubmit(save)">{{ saveButtonText }}</loading-button>
                </div>

                <div class="col-sm-3 top-20-xs">
                  <button type="button" :class="[cancelButtonClass, 'a-button-size']" @click="dismissModal">{{ cancelButtonText }}</button>
                </div>
              </div>

              <div class="error-text top-10" v-if="(failed && hasErrorSlot) || hasResponseErrors">
                <div class="size-text-12px">
                  <slot name="errorMessage"></slot>
                  <span v-if="hasResponseErrors">{{ responseErrorsDisplay }}</span>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'EditModal',

  components: {
    ModalCloseButton,
    ValidationObserver,
    LoadingButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    input: {
      type: [Object, Array, String, Number],
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onCancel: {
      type: Function,
      default: () => {}
    },

    prompt: {
      type: String,
      default: 'Edit'
    },

    title: {
      type: String,
      required: true
    },

    backdrop: {
      type: Boolean,
      default: false
    },

    modalSize: {
      type: String,
      default: 'md'
    },

    saveButtonClass: {
      type: String,
      default: 'nv-button-blue'
    },

    saveButtonText: {
      type: String,
      default: 'Save'
    },

    cancelButtonClass: {
      type: String,
      default: 'secondary-btn-blue'
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel'
    }
  },

  data() {
    return {
      copyBuilt: false,
      responseErrors: []
    };
  },

  computed: {
    hasErrorSlot() {
      return this.$slots.errorMessage;
    },

    hasResponseErrors() {
      return this.responseErrors.length > 0;
    },

    responseErrorsDisplay() {
      return this.responseErrors.join('; ');
    }
  },

  watch: {
    modalOpen(open) {
      if (open) {
        this.copyBuilt = false;
        this.$emit('input', cloneDeep(this.input));
        this.copyBuilt = true;
      }
    }
  },

  methods: {
    save() {
      this.responseErrors = [];
      LoadingService.loading('editModalSubmit');

      return Promise.resolve(this.onSave())
        .then(() => {
          this.closeModal();
          this.$refs.validationObserver.reset();
        })
        .catch((response) => {
          this.responseErrors = (response?.response?.data?.errors || []);
        })
        .finally(() => {
          LoadingService.done('editModalSubmit');
        });
    },

    cancel() {
      this.closeModal();
      this.$refs.validationObserver.reset();
      this.responseErrors = [];
      this.onCancel();
    }
  }
};
</script>
